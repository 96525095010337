import moment from 'moment';
import {
  BookingContact,
  PassengerCategoryItems,
  PassengerFormItem,
  RecommendationType,
  RoadPassengerCategoryItems,
  RoadPassengerFormItem,
} from './types';
import {RoadSearchStore, RoadSearchStoreState} from '../../store/roadSearch/types';

export const generateCategoryInputs = (numberOfInputs: number, savedData = null) => {
  const passengerCategory = new Array(numberOfInputs).fill({
    title: '',
    gender: '',
    city: '',
    country: 'ng',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: '',
    passportNumber: '',
    address: '',
    passportExpiration: '',
    issuanceCountry: '',
    issuanceDate: '',
    frequentFlyerAirline: '',
    frequentFlyerNumber: '',
  });

  if(savedData) {
    passengerCategory[0] = {
      title: savedData.title || savedData.namePrefix || '',
      gender: savedData.gender ||'',
      city: savedData.city || '',
      country: 'ng',
      firstName: savedData.firstName || '',
      lastName: savedData.lastName || '',
      email: savedData.email || '',
      phoneNumber: savedData.phoneNumber || '',
      dob: savedData.dob ? moment(savedData.dob) : '',
      passportNumber: savedData.passportNumber || '',
      address: savedData.address || '',
      passportExpiration: savedData.passportExpiration ? moment(savedData.passportExpiration) : '',
      issuanceCountry: savedData.issuanceCountry || '',
      issuanceDate: savedData.issuanceDate ? moment(savedData.issuanceDate) : '',
      frequentFlyerAirline: savedData.frequentFlyerAirline || '',
      frequentFlyerNumber: savedData.frequentFlyerNumber || '',
    }
  }

  return passengerCategory.map(({
    title,
    gender,
    city,
    country,
    firstName,
    lastName,
    email,
    dob,
    phoneNumber,
    passportNumber,
    address,
    passportExpiration,
    issuanceCountry,
    issuanceDate,
    frequentFlyerAirline,
    frequentFlyerNumber
  }, key) => ({
    id: key,
    title,
    gender,
    city,
    country,
    firstName,
    lastName,
    email,
    dob,
    phoneNumber,
    passportNumber,
    address,
    passportExpiration,
    issuanceCountry,
    issuanceDate,
    frequentFlyerAirline,
    frequentFlyerNumber
  }));
};
export const generateRoadCategoryInputs = (numberOfInputs: number, savedData = null) => {
  const passengerCategory = new Array(numberOfInputs).fill({
    namePrefix: '',
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    nextOfKinName: '',
    nextOfKinEmail: '',
    nextOfKinPhoneNumber: '',
  });


  if(savedData) {
    passengerCategory[0] = {
      namePrefix: savedData.namePrefix || savedData.title || '',
      gender: savedData.gender || '',
      firstName: savedData.firstName || '',
      lastName: savedData.lastName || '',
      email: savedData.email || '',
      phoneNumber: savedData.phoneNumber || '',
      address: savedData.address || '',
      nextOfKinName: savedData.nextOfKinName || '',
      nextOfKinEmail: savedData.nextOfKinEmail || '',
      nextOfKinPhoneNumber: savedData.nextOfKinPhoneNumber || ''
    }
  }

  return passengerCategory.map(({
    namePrefix,
    gender,
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    nextOfKinName,
    nextOfKinPhoneNumber,
    nextOfKinEmail,
  }, key) => ({
    id: key,
    namePrefix,
    gender,
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    nextOfKinName,
    nextOfKinPhoneNumber,
    nextOfKinEmail
  }));
};

const getGender = (title: string) => {
  switch (title) {
    case 'Mr':
      return 'male';
    default:
      return 'female';
  }
};
export const buildPassengerItemPayload = (data: PassengerFormItem, pType: string) => ({
  NamePrefix: data.title,
  PassengerTypeCode: pType,
  FirstName: data.firstName.trim(),
  LastName: data.lastName.trim(),
  Gender: getGender(data.title),
  Email: data.email,
  BirthDate: data.dob.format('YYYY-MM-DD'),
  FrequentFlyerNumber: data.frequentFlyerNumber || '',
  FrequentFlyerAirline: data.frequentFlyerAirline || '',
  Address: {
    ContactName: data.firstName.trim(),
    ContactEmail: data.email,
    AddressLine1: data.address,
    City: data.city,
    CountryCode: data.country.toUpperCase(),
  },
  Documents: [{
    DocType: 'DOCS',
    InnerDocType: 'Passport',
    DocID: data.passportNumber,
    IssueCountryCode: data.issuanceCountry.toUpperCase(),
    IssueLocation: data.issuanceCountry.toUpperCase(),
    BirthCountryCode: data.country.toUpperCase(),
    ExpiryDate: data.passportExpiration ? data.passportExpiration.format('YYYY-MM-DD') : undefined,
    BirthDate: data.dob.format('YYYY-MM-DD'),
    EffectiveDate: data.issuanceDate ? data.issuanceDate.format('YYYY-MM-DD') : undefined,
  }],
  //  Unknown
  NumberOfBaggages: 0,
  NumberOfBaggages1: 0,
  HandLuggages: 0,
  HandLuggages1: 0,
});

export const buildPassengersPayload = (data: PassengerCategoryItems, selectedFlight: AirlineResultItem[], contact: BookingContact, isDomestic: boolean) => {
  const adults = data.adults.map((adult) => buildPassengerItemPayload(adult, 'ADT'));
  const children = data.children.map((adult) => buildPassengerItemPayload(adult, 'CHD'));
  const infants = data.infants.map((adult) => buildPassengerItemPayload(adult, 'INF'));

  const travelers = [...adults, ...children, ...infants];
  const primaryContact = adults[0];

  const recommendationData = isDomestic ? {
    SelectedFlights: selectedFlight.map((sf, index) => ({
      RecommendationID: sf.Properties.RecommendationID,
      CombinationID: sf.Properties.CombinationID,
      GdsId: sf.GDSId,
      AgentId: sf.AgentId,
      FlightRouteIndex: index,
    })),
    SessionId: selectedFlight[0].Properties.TripsSessionId,
  } : {
    SessionId: selectedFlight[0].Properties.TripsSessionId,
    RecommendationID: selectedFlight[0].Properties.RecommendationID,
    CombinationID: selectedFlight[0].Properties.CombinationID,
    GdsId: selectedFlight[0].GDSId,
    AgentId: selectedFlight[0].AgentId,
  };
  return {
    AirTravellers: travelers,
    PromoCode: data.promoCode,
    BillingAddress: {
      ContactName: `${primaryContact.FirstName} ${primaryContact.LastName}`,
      ContactEmail: contact.email,
      ContactMobileNo: contact.phone,
      AddressLine1: primaryContact.Address.AddressLine1,
      City: primaryContact.Address.City,
      CountryCode: contact.country.toUpperCase(),
    },
    ...recommendationData,
  };
};

export const buildRoadPassengerSeatPayload = (selectedSeats: SelectedBusSeat[], seatIndex) => {
  if(selectedSeats.length === 1) return [selectedSeats[0][seatIndex].SeatNumber];

  return [selectedSeats[0][seatIndex].SeatNumber, selectedSeats[1][seatIndex].SeatNumber];
}

export const buildRoadPassengerItemPayload = (data: RoadPassengerFormItem, pType: string, selectedSeats: SelectedBusSeat[], seatIndex) => {
  return ({
    PassengerTypeCode: pType,
    FirstName: data.firstName.trim(),
    LastName: data.lastName.trim(),
    NamePrefix: data.namePrefix,
    SeatNumbers: buildRoadPassengerSeatPayload(selectedSeats, seatIndex),
  })
};

const buildRodBusesPayload = (resultItems: BusResultItem[], buses: AvailableBus[]) => {
  return resultItems.map((resultItem, index) => {
    return {
        RecommendationID: buses[index].RecommendationId,
        CombinationID: resultItem.CombinationId,
        GdsId: resultItem.GdsId,
        AgentId: resultItem.AgentId,
        BusRouteIndex: resultItem.BusRouteIndex,
    }
  });
}

export const buildRoadPayload = (data: RoadPassengerCategoryItems, store: RoadSearchStoreState, contact: BookingContact) => {
  const { formData, selectedBus = [], selectedBusSeat = [], selectedResult = [] } = store;
  const adults = data.adults.map((adult, index) => buildRoadPassengerItemPayload(adult, 'ADT', selectedBusSeat, index));
  const primaryContact = adults[0];
  const travelers = [...adults];
  const nextOfKin = data.adults[0];

  return {
    SessionId: store.tripsSessionId,
    Travellers: travelers,
    NumberOfSeats: travelers.length,
    IsRoundTrip: formData.TripType === 'round_trip',
    SelectedBuses: buildRodBusesPayload(selectedResult, selectedBus),
    BillingAddress: {
      ContactName: `${primaryContact.FirstName} ${primaryContact.LastName}`,
      ContactEmail: contact.email,
      ContactPhoneNumber: contact.phone,
      AddressLine1: contact.address,
      NextOfKinDetails: {
        Name: nextOfKin.nextOfKinName.trim(),
        Email: nextOfKin.nextOfKinEmail.trim(),
        PhoneNumber: nextOfKin.nextOfKinPhoneNumber,
      },
    },
  };
};

export const buildWaterPayload = (data: RoadPassengerCategoryItems, recommendationData: RecommendationType, formData: RoadSearchStore) => {
  const adults = data.adults.map((adult) => buildRoadPassengerItemPayload(adult, 'ADT', [], 0));
  const children = data.children ? data.children.map((child) => buildRoadPassengerItemPayload(child, 'CHD', [], 0)) : [];
  const infants = data.infants ? data.infants.map((infant) => buildRoadPassengerItemPayload(infant, 'INF', [], 0)) : [];
  const travelers = [...adults, ...children, ...infants];

  const primaryContact = data.adults[0];

  return {
    Travellers: travelers,
    NumberOfSeats: travelers.length,
    IsRoundTrip: formData.TripType === 'round_trip',
    BillingAddress: {
      ContactName: `${primaryContact.firstName} ${primaryContact.lastName}`,
      ContactEmail: primaryContact.email,
      ContactPhoneNumber: primaryContact.phoneNumber,
      AddressLine1: primaryContact.address,
      NextOfKinDetails: {
        Name: primaryContact.nextOfKinName,
        Email: primaryContact.nextOfKinEmail,
        PhoneNumber: primaryContact.nextOfKinPhoneNumber,
      },
    },
    ...{
      SessionId: recommendationData.SessionId,
      RecommendationID: recommendationData.RecommendationID,
      CombinationID: recommendationData.CombinationID,
    },
  };
};

export const getPassengerData = () => {
  return JSON.parse(localStorage.getItem('paxData') || 'null');
}

export const savePassengerData = (data) => {
  const savedData = getPassengerData() || {};
    localStorage.setItem('paxData', JSON.stringify({
        ...savedData,
        ...data,
        dob: ''
    }));
}

export const getInitialFormData = () => {
  const data = getPassengerData();

  if(!data) {
    return {
      contactPhone: "",
      contactEmail: "",
      country: "",
    }
  }

  return {
    contactPhone: data.contactPhone || "",
    contactEmail: data.contactEmail || "",
    country: data.country || "",
  }
}
